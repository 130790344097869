<template>
  <div>
    <data-tables sort-key="1" desc :endpoint="`v1/user_logs?id_user=${id}`" :fields="fields">
      <template v-slot:1="{item}">
        {{item[1] | fulldate}}
      </template>
    </data-tables>
    <small>Aktivitas yang ditampilkan adalah 100 aktivitas terakhir</small>
  </div>
</template>

<script>
import DataTables from '@/my-components/DataTables.vue'
export default {
  components:{
    DataTables
  },
  data(){
    return {
      id: this.$route.params.id,
      fields:{
        0:{sortable: true, searchable: true, label:'Aksi'},
        1:{sortable: true, searchable: true, label:'Log'},
      }
    }
  }
}
</script>

<style>

</style>